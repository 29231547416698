<template>
  <div class="search_box" :style="[{background:all.bgColor},{color:all.textColor},{height:all.boxHeight}]">
    <!--左边-->
    <img v-if="all.isShowReturn" class="search_go_img" :src="all.urlImg" alt="" @click="all.callbackGo()">
    <!--中间-->
    <div class="search_middle" @click="all.callbackBtnGo()">
      <!--搜索 searchOut-->
      <div
        v-if="all.isShowSearch"
        class="search_input"
        :style="[{width:all.searchWidth},{border:'1px solid' + all.searchBorderColor}]"
      >
        <img class="search_img" src="../../assets/images/search.png" alt="">
        <input
          v-model="all.isSearch"
          type="text"
          :placeholder="all.placeholder"
          class="search_go_input"
          @search="all.callbackSearch(all.isSearch)"
          @blur="all.callbackBlur()"
          @keyup.enter="keyupChange"
          @focus="all.callbackFocus()"
          @click="all.callbackClick(all.isSearch)"
          @input="all.callbackOnInput(all.isSearch)"
        >
      </div>
      <!--标题-->
      <div v-else class="title" :style="{color:'#000'}">
        <span>{{ all.titleData }}</span>
      </div>
    </div>
    <!--右边-->
    <div v-if="all.btnRefresh" class="search_update_operation" @click="all.callbackRefresh()">
      <span :style="[{color:all.rightTitleColor},{fontSize: all.rightTitleFontSize}]">
        {{ all.rightTest }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Search',
  props: {
    searchOut: {
      type: Object,
      default: () => {
        return {
          bgColor: '', // 背景颜色默认值
          isShowSearch: true, // 控制搜索跟标题
          titleData: '', // title
          btnRefresh: false, // 右边 显隐
          urlImg: '', // 左边 img
          isSearch: '', // input val 值
          placeholder: '搜索内容', // input placeholder
          isShowReturn: false,
          textColor: '',
          callbackRefresh: () => {
          }, // 搜索 右边刷新 回调（暂时隐藏）
          callbackGo: () => {
          }, // 搜索 返回 回调
          callbackFocus: () => {
          }, // 搜索 Focus事件回调
          callbackDown: () => {
          }, // 搜索 keydown事件回调
          callbackBlur: () => {
          }, // 搜索 Blur事件回调
          callbackSearch: () => {
          }, // 搜索 Search事件回调
          callbackClick: () => {
          }, // 搜索 Click事件回调
          callbackBtnGo: () => {
          }, // 点击输入框跳转
          callbackOnInput: () => {
          }
        }
      }
    }

  },
  data() {
    return {
      isShowIco: false, // 默认 输入框后面删除 img logo
      isShowSearch: false, // 默认 显隐  input 还是 title
      bgColor: '', // 默认 背景颜色
      btnRefresh: false, // 右面按钮 点击刷新显隐
      titleData: '', // title 标题
      placeholder: '输入内容', // 默认 placeholder
      isSearch: '', // 默认 isSearch input 值
      urlImg: require('@/assets/images/lefe_go.png'), // 默认img
      isShowReturn: false,
      textColor: '#fff', // 默认 title 字体颜色
      rightTest: '取消', // 默认 右边 文字 内容
      rightTitleColor: '#333', // 默认 右边 文字 颜色
      rightTitleFontSize: '14px', // 默认 右边 文字 大小
      searchBorderColor: '#F7263C', // 默认 边框 颜色
      boxHeight: '42px' // 默认 高度
    }
  },
  computed: {
    all() {
      const Obj = this.searchOut
      // isShowSearch input title 显隐
      if (!Obj.isShowSearch) {
        // Obj.bgColor = '#000'
        Obj.isShowSearch = this.isShowSearch
      }
      // textColor test 颜色
      if (!Obj.textColor) {
        Obj.textColor = this.textColor
      }
      // bgColor 背景颜色
      if (!Obj.bgColor) {
        Obj.bgColor = this.bgColor
      }
      // boxHeight 高度
      if (!Obj.boxHeight) {
        Obj.boxHeight = this.boxHeight
      }
      // btnRefresh  右边 显隐
      if (!Obj.btnRefresh) {
        Obj.btnRefresh = this.btnRefresh
      }
      // titleData 标题
      if (!Obj.titleData) {
        Obj.titleData = this.titleData
      }
      // search.placeholder   input placeholder
      if (!Obj.placeholder) {
        Obj.placeholder = this.placeholder
      }
      // isSearch  input  val
      if (!Obj.isSearch) {
        Obj.isSearch = this.isSearch
      }
      // img  搜索img logo
      if (!Obj.urlImg) {
        Obj.urlImg = this.urlImg
      }
      // isShowReturn  左边 显隐
      if (!Obj.isShowReturn) {
        Obj.isShowReturn = this.isShowReturn
      }
      // searchWidth 搜索 宽度
      if (!Obj.searchWidth) {
        Obj.searchWidth = this.searchWidth
      }
      // rightTest  右边 内容 title
      if (!Obj.rightTest) {
        Obj.rightTest = this.rightTest
      }
      // rightTitleColor  右边 字体 颜色
      if (!Obj.rightTitleColor) {
        Obj.rightTitleColor = this.rightTitleColor
      }
      // rightTitleFontSize  右边 字体 大小
      if (!Obj.rightTitleFontSize) {
        Obj.rightTitleFontSize = this.rightTitleFontSize
      }
      // searchBorderColor   搜索 边框 颜色
      if (!Obj.searchBorderColor) {
        Obj.searchBorderColor = this.searchBorderColor
      }
      return Obj
    }

  },
  mounted() {
    this.titleData = document.title
  },
  methods: {
    keyupChange() {
      this.all.callbackDown(this.all.isSearch)

      //   if(this.all.isSearch.length===0){
      // this.all.callbackDown(this.all.placeholder)
      //   }else{
      //     this.all.callbackDown(this.all.isSearch)
      //   }
    }
  }

}
</script>

<style lang="less" scoped>
.search_box {
  width: 100%;
  height: 42px;
  padding: 4px 0;
  position: relative;
  box-sizing: border-box;

  .search_go_img {
    width: 12px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }

  .title {
    height: 100%;
    position: relative;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .search_middle {
    height: 100%;

    .search_input {
      width: 84%;
      height: 100%;
      border: 1px solid #F7263C;
      border-radius: 20px;
      position: relative;
      background: #fff;
      overflow: hidden;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;

      .search_img {
        width: 22px;
        position: absolute;
        left: 7px;
        top: 50%;
        transform: translate(0, -50%);
      }

      .search_go_input {
        width: 83%;
        font-size: 13px;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translate(0, -50%);
        color: #999;
      }

      .search_delete {
        width: 14px;
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
  }

  .search_update_operation {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 12px;
    color: #333;
    font-size: 10px;
  }
}

</style>
