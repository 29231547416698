<template>
  <div>
    <headSearch :search-out="headGo" />
    <!--爆款拼团-->
    <bigMoney v-if="temptype==1" :settop="settop" />
    <!--美妆个户-->
    <beautifulClothes v-if="temptype==2" :settop="settop" />
  </div>

</template>

<script>
import headSearch from '@/component/head/search'
import bigMoney from '@/component/yueTao/bigMoney'
import beautifulClothes from '@/component/yueTao/beautifulClothes'

export default {
  name: 'TabBoxIndex',
  components: {
    bigMoney,
    beautifulClothes,
    headSearch
  },
  data() {
    return {
      settop: '40px',
      temptype: this.$route.query.temptype,
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: document.title,
        callbackGo: this.callbackGo
      }
    }
  },
  mounted() {
    // eslint-disable-next-line eqeqeq
    if (this.$route.query.temptype == 1) {
      this.headGo.titleData = '爆款拼团'
      // eslint-disable-next-line eqeqeq
    } else if (this.$route.query.temptype == 2) {
      this.headGo.titleData = '美妆个户'
    } else {
      this.headGo.titleData = '食品生鲜'
    }
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>

/deep/ .mescroll {
  top: 50px;
}
</style>
