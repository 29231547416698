<template>
  <div class="Bigmoney">
    <!--爆款拼团-->
    <!-- list -->
    <skeleton v-if="!imgurl&&list.length===0" type="indexList" />

    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div id="dataList" slot="scrollList">
        <div v-if="imgurl" class="banner">
          <img :src="imgurl" alt="">
        </div>
        <div>
          <div v-for="(item,index) in list" :key="index" class="list-item" @click="godetail(item)">
            <div class="list-item-img">
              <div class="markerUrlIconMall">
                <img :src="item.markerUrl" alt="">
              </div>
              <img :src="item.goodsImg" alt="">

            </div>

            <div class="left">
              <div class="top">{{ item.goodsName }}</div>
              <div class="center">
                <span style="color:red;">￥</span>
                <span style="color:red;font-size:18px;">{{ item.teamPrice }}</span>
                <span style="color:#cccccc;text-decoration:line-through">官网价：{{ item.originPrice }}</span>
              </div>
              <div class="bottom">

                <div>
                  <span style="color:#925800;width:45%; ">{{ item.needer }}人团</span><span
                    style="color:#ffffff;width:55%;"
                  >立即开团</span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import { assembleList } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'
import skeleton from '@/component/skeleton/index'

export default {
  name: 'BigMoney',
  components: {
    mescroll, skeleton
  },
  props: {
    settop: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      page: 1,
      clannel: 7,
      pageSize: 10,
      imgurl: '',
      list: [],
      mescrollDown: {
        use: true,
        top: '150px',
        bottom: '0'
      },
      mescrollUp: {
        use: true,
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.settop !== 0) {
        this.mescrollDown.top = this.settop + 'px'
      }
    })
  },
  methods: {
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const that = this
      that.page = pageNum
      that.pageSize = pageSize
      assembleList({
        page: that.page,
        pageSize: that.pageSize
      }).then(res => {
        if (Number(res.code) === 200) {
          this.imgurl = res.data.ad[0].url
          successCallback(res.data.item)
        }
      })
    },
    godetail(item) {
      const obj = {
        product_type: 7, // 暂时借口没有返回 这个字段 product_type
        goodId: item.goodsId,
        skuId: item.skuId,
        id: item.id
      }
      this.$store.dispatch('godetail', obj)
    }
  }
}

</script>

<style lang="less" scoped>
.Bigmoney {
  width: 100%;
  //height: 100%;
  // background: red;
  .banner {
    width: 94%;
    //height: 160px;
    margin: 0 auto;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .list-item {
    background: #ffffff;
    width: 94%;
    height: 110px;
    display: flex;
    align-items: center;
    margin: 10px auto;
    border-radius: 10px;
    padding: 10px;

    //     img{
    //   width: 25%;
    //   height: 90%;
    //   margin-left: 20px;
    // }

    .list-item-img {
      position: relative;
      width: 30%;
      overflow: hidden;
      border-radius: 10px;
      height: 100%;
      // margin-left: 10px;
      // img{
      //   height: 100%;
      // }

    }

    .left {
      width: 70%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      margin-left: 20px;

      div {
        width: 100%;
      }

      .top {
        height: 36px;
        line-height: 18px;
        font-size: 14px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 216px;
        // height: 33%;
        // margin-left: 44px;
      }

      .center {
        height: 30%;
        display: flex;
        align-items: flex-end;
        margin: 0 0 4px 0;
      }

      .bottom {
        height: 25%;

        div {
          width: 50%;
          height: 100%;
          background: url('../../assets/images/gopt.png');
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }

  }
}

.markerUrlIconMall {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;

  img {
    width: auto;
    height: 40px;
  }

}
</style>
