<template>
  <div class="recommended">
    <div v-if="list.length != '0'">
      <h1>
        <!--              <img src="../../assets/images/fresh_icon3.png" />-->
        <!--              <em>推荐商品</em>-->
        <!--              <span>猜你喜欢</span>-->
        <span class="recommended_title">·<em class="recommended_style">推荐商品</em>·</span>
      </h1>
      <div class="recommended_list">

        <!-- <div class="home-product grace-space-between">
                  <div class="grace-waterfall-item" style="width: 49%;"  v-for="(lists, indexTab) in newList" :key="indexTab">
                      <div class="items" v-for="(item,index) in lists" :key="index" @click="targetLink(item.act_id)">
                          <imgLoading  mode="widthFix" :src="item.goodCover" />

                        <img mode="widthFix" :src="item.goodCover" />
                          <div class="item-text">
                              <h3>{{item.act_name}}</h3>
                              <div class="flex-start">
                                  <span>当前价{{item.id}}</span>
                                  <h4>{{item.last_price?item.last_price+'元':item.formated_start_price}}</h4>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->

        <ul id="dataList" class="data-list">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="recommended_item"
            @click="godetail(item)"
          >
            <imgLoading :src="item.goodCover" />

            <p class="tit">{{ item.goodName }}</p>
            <p class="desc">
              优选材质，便携使用便携优选材质，便携使用便携
            </p>
            <p class="money">
              <em>¥</em>
              {{ item.goodVipPrice }}
              <span>
                <em>原价:¥</em>
                {{ item.goodPrice }}
              </span>
            </p>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import imgLoading from '@/component/imgLoading'
// import Waterfalls from "@/component/Waterfalls/index";
export default {
  name: 'Fresh',
  components: {
    imgLoading
    //  Waterfalls
  },
  props: {
    list: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      options: {
        col: 2
      },
      errorImg: 'this.src="' + require('@/assets/images/wxtwo.jpg') + '"',
      auctionList: [[], []]
    }
  },
  computed: {
    newList() {
      const myData = this.list // 数据列表

      let auctionList = []; const lArr = []; const rArr = []
      for (var i = 0; i < myData.length; i++) {
        if (i % 2 == 0) {
          lArr.push(myData[i])
        } else {
          rArr.push(myData[i])
        }
        auctionList = [lArr, rArr]
      }
      return auctionList
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    onReachBottom() {
      return [{ id: 1 }, { id: 2 }]
    },
    getList() { // 更多藏品推荐
      const myData = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }] // 数据列表
      const lArr = this.auctionList[0]
      const rArr = this.auctionList[1]
      for (var i = 0; i < myData.length; i++) {
        if (i % 2 == 0) {
          lArr.push(myData[i])
        } else {
          rArr.push(myData[i])
        }
        this.auctionList = [lArr, rArr]
      }
      //  if(myData.length<this.listQuery.page_size&&(this.listQuery.page==1||this.listQuery.page!=1)){
      //      this.loadingType=2;
      //      return
      //  }
    },

    // 跳转到详情
    godetail(item) {
      // /goodsDetailZY?liveId='直播ID'&liveUserId='直播人ID'&parentTypeId='产品类型'&goodsId='产品ID'&skuid='产品规格ID'
      this.$router.push({ path: '/goodsDetailZY', query: { goodsId: item.goodId, skuid: item.skuId }})
    }

  }
}
</script>
<style lang="less" scoped>
/*精选商品*/
.recommended {
  width: 351px;
  margin: 0 auto;

  // padding-bottom: 50px;
  h1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 16px;
    color: #141414;
    height: 21px;
    text-align: center;

    img {
      width: 21px;
      height: 21px;
      display: inline-block;
      margin-right: 4px;
    }

    em {
      font-style: normal;
    }

    span {
      //color: #999;
      font-size: 14px;
      margin-left: 10px;
    }

    .recommended_title {
      text-align: center;
      margin: 0 auto;
    }

    .recommended_style {
      font-size: 16px;
      padding: 0 6px;
      box-sizing: border-box;
    }
  }

  .data-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;

    .recommended_item:nth-child(odd) {
      margin-right: 9px;
    }

    .recommended_item {
      width: 171px;
      padding: 0 16px;
      background: #fff;
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      margin-bottom: 9px;

      .tit {
        color: #191919;
        font-size: 15px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /*要显示的行数*/
        -webkit-box-orient: vertical;
        margin-top: 10px;
        line-height: 22px;
        font-weight: bold;
      }

      .desc {
        color: #999;
        font-weight: bold;
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1; /*要显示的行数*/
        -webkit-box-orient: vertical;
        height: 12px;
      }

      .money {
        font-size: 17px;
        color: #c82519;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 4px;

        span {
          text-decoration: line-through;
          color: #bbbbbb;
          font-size: 12px;
          margin-left: 2px;
          line-height: 23px;
        }

        em {
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }

  .recommended_list,
  .van-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;

    .recommended_item:nth-child(odd) {
      margin-right: 9px;
    }

    .recommended_item {
      width: 171px;
      padding: 0 16px;
      background: #fff;
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      margin-bottom: 9px;

      .tit {
        color: #191919;
        font-size: 15px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /*要显示的行数*/
        -webkit-box-orient: vertical;
        margin-top: 10px;
        line-height: 22px;
        font-weight: bold;
      }

      .desc {
        color: #999;
        font-weight: bold;
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1; /*要显示的行数*/
        -webkit-box-orient: vertical;
        height: 12px;
      }

      .money {
        font-size: 17px;
        color: #c82519;
        font-weight: bold;
        margin-top: 20px;

        span {
          text-decoration: line-through;
          color: #bbbbbb;
          font-size: 12px;
          margin-left: 2px;
          line-height: 23px;
        }

        em {
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }
}

.home-product {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .grace-waterfall-item {
    background: #000;

    // float: left;
    //  width:47%;
    //  &:nth-child(2n+2) {
    //  margin: 0 10px;
    //  }

    .items {
      margin-bottom: 10px;
      overflow: hidden;
    }

    // width: 100%;
    // display: flex;
    //     flex-flow: row wrap;
    //     justify-content: space-between;
    //  .items{
    //    width: 48%;
    //  }
  }

}

// .home-product-container{
//       width:678rpx;
//       margin:0 auto;
//       margin-top:54rpx;
//       padding-bottom:30rpx;
//       .h-title{
//           width:180rpx;
//           height:30rpx;
//       }
//   }
//     .home-product {
//         display: flex;
//         justify-content: space-between;
//         flex-wrap:wrap;
//         image{
//             width:100%;
//             border-top-left-radius:6rpx;
//             border-top-right-radius:6rpx;
//         }
//     }
//     .items{
//         width:50%;
//         border-radius:6rpx;
//         margin-top:26rpx;
//         border:2rpx solid  #e5e5e5;
//         .item-text{
//             h3{
//                 width:286rpx;
//                 margin:0 auto;
//                 line-height:38rpx;
//                 color:#282828;
//                 font-size:26rpx;
//                 margin-top:20rpx;
//                 height: 70rpx;
//             }
//             .flex-start{
//                 width:286rpx;
//                 margin:0 auto;
//                 display:flex;
//                 align-items: center;
//                 margin-top:16rpx;
//                 padding-bottom:22rpx;
//                 span{
//                     display:block;
//                     width:80rpx;
//                     height:32rpx;
//                     border:1rpx solid #FC7F25;
//                     border-radius:4rpx;
//                     color:#FC7F25;
//                     font-size:22rpx;
//                     text-align: center;
//                     line-height: 32rpx;
//                     margin-right:20rpx;
//                 }
//                 h4{
//                     font-size:30rpx;
//                     color:#FC7F25;
//                     font-weight:bold;
//                 }
//             }
//         }
//     }

</style>
